import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { Layout, Section, CoverList, Event, EventCardList } from 'components';

const EventsOverview = (props) => {
  const data = useStaticQuery(query);

  return (
    <Layout>
      <div className='uk-padding-large'></div>
      {data.eventsNow.nodes[0] ? (
        <Section title='진행중인 이벤트'>
          <EventCardList events={data.eventsNow} />
        </Section>
      ) : null}
      <Section title='강좌 / 아카데미'>
        <div className='uk-grid uk-grid-match'>
          <CoverList component={Event} data={data.academy.nodes} rows={2} />
        </div>
      </Section>
      <Section title='지난 이벤트'>
        <div className='uk-grid uk-grid-match'>
          <CoverList component={Event} data={data.eventsPast.nodes} rows={2} />
        </div>
        <div className='uk-flex uk-flex-right'>
          <Link to={`/events-all`} className='uk-link-reset'>
            <button className='uk-button uk-button-default'>
              모든 이벤트 보기
            </button>
          </Link>
        </div>
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query {
    eventsNow: allStrapiEvents(
      filter: { type: { eq: "event" }, status: { eq: "now" } }
      limit: 2
      sort: { order: DESC, fields: date }
    ) {
      nodes {
        title
        description
        type
        short_description
        slug
        date
        id
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                height: 700
                aspectRatio: 0.72
                transformOptions: { fit: FILL }
              )
            }
          }
        }
        location {
          title
          slug
        }
      }
    }
    academy: allStrapiEvents(
      sort: { order: DESC, fields: date }
      limit: 4
      filter: { type: { eq: "academy" } }
    ) {
      nodes {
        title
        description
        type
        short_description
        slug
        date
        id
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                aspectRatio: 0.70
                transformOptions: { fit: FILL }
                height: 500
              )
            }
          }
        }
        location {
          title
          slug
        }
      }
    }
    eventsPast: allStrapiEvents(
      filter: { type: { eq: "event" }, status: { eq: "past" } }
      limit: 4
      sort: { order: DESC, fields: date }
    ) {
      nodes {
        title
        description
        type
        short_description
        slug
        date
        id
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                aspectRatio: 0.70
                transformOptions: { fit: FILL }
                height: 600
              )
            }
          }
        }
        location {
          title
          slug
        }
      }
    }
  }
`;

export default EventsOverview;
